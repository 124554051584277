// TODO: use OpenAPI definitions
// TODO: Split file into several files
import {base, withOptions} from "./api-ts"

export const getDevices = async () => {
    const url = `${base}/v1/devices`
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()

    let devices = data.data

    for (const d of devices) {
        d.organization = data.organizations.find(o =>
            o ? o.id === d.organization_id : false
        )
    }

    return devices
}

export const getSoundlevels = async (
    deviceIds,
    organizationIds,
    start,
    end
) => {
    let url = `${base}/v1/soundlevels`
    let params = new URLSearchParams({
        start: Math.floor(start.getTime() / 1000),
        end: Math.floor(end.getTime() / 1000),
    })
    deviceIds.map(id => params.append("device", id))
    organizationIds.map(id => params.append("organization", id))
    url = url + "?" + params

    let response = await fetch(url, withOptions())
    let data = await response.json()

    const soundLevels = data.data
    return soundLevels
}

export const getVibrations = async (deviceIds, organizationIds, start, end) => {
    let url = `${base}/v1/vibration`
    let params = new URLSearchParams({
        start: Math.floor(start.getTime() / 1000),
        end: Math.floor(end.getTime() / 1000),
    })
    deviceIds.map(id => params.append("device", id))
    organizationIds.map(id => params.append("organization", id))
    url = url + "?" + params

    let response = await fetch(url, withOptions())
    let data = await response.json()

    const vibrations = data.data
    return vibrations
}

// Untested async variant of getAccount
export const getAccount = async () => {
    const url = `${base}/v1/account`

    let response = await fetch(url, withOptions())
    let data = await response.json()
    const account = data.data

    return account
}

//TODO: Add signal argument
export const getAccountWithAbort = (signal) => {
    return new Promise((resolve, reject) => {
        const url = `${base}/v1/account`

        fetch(url, withOptions(), signal)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Request unsuccessful.")
                }
                return res.json()
            })
            .then((data) => {
                const account = data.data;
                resolve(account);
            })
            .catch((err) => {
                reject(err);
            })
    })
}

// User login
export const postUser = async (email, password, remember) => {
    const url = `${base}/login`

    var userData = new FormData()
    userData.append("email", email)
    userData.append("password", password)
    userData.append("remember", remember)

    let response = await fetch(url, withOptions({
        method: "POST",
        body: userData,
    }))

    return response
}

export const editDevice = async (deviceId, deviceAttributes) => {
    let url = `${base}/v1/devices/${deviceId}`

    let formData = deviceAttributes

    let response = await fetch(url, withOptions({
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    }))

    return response
}

//TODO: Any validation with error reporting here?
export const registerDevice = async deviceAttributes => {
    let url = `${base}/v1/devices`

    let response = await fetch(url, withOptions({
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(deviceAttributes),
    }))

    return response
}

export const getDeviceTypes = async () => {
    let url = `${base}/v1/devicetypes`
    let response = await fetch(url, withOptions())
    let data = await response.json()

    let deviceTypes = data.data

    return deviceTypes
}

export const getDeviceMetrics = async (deviceIds, startTime, endTime) => {
    let url = `${base}/v1/devices/metrics`

    let params = new URLSearchParams({
        start: Math.floor(startTime.getTime() / 1000),
        end: Math.floor(endTime.getTime() / 1000),
    })
    deviceIds.map(id => params.append("device", id))

    url = url + "?" + params

    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data.data
}

export const getBuildings = async (orgIdList) => {
    let url = `${base}/v1/buildings`
    let params = new URLSearchParams({})
    orgIdList.forEach(id => {
        params.append("organization_id", id)
    })
    url = url + "?" + params
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data["data"]
}

export const getBuilding = async (buidlingId) => {
    let url = `${base}/v1/buildings/`
    url = url + buidlingId
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data["data"]
}

export const getRooms = async (organizationIds, buildingIds) => {
    let url = `${base}/v1/rooms`
    let params = new URLSearchParams({})
    organizationIds.forEach(id => {
        params.append("organization_id", id)
    })
    buildingIds.forEach(id => {
        params.append("building_id", id)
    })
    url = url + "?" + params
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data["data"]
}

export const getRoom = async (roomId) => {
    let url = `${base}/v1/rooms/${roomId}`
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data["data"]
}

export const getOrganization = async (organizationId) => {
    let url = `${base}/v1/organizations/${organizationId}`
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data["data"]
}

export const getAssets = async (organizationIds, buildingIds, roomIds) => {
    let url = `${base}/v1/assets`
    let params = new URLSearchParams({})
    organizationIds.forEach(id => {
        params.append("organization_id", id)
    })
    buildingIds.forEach(id => {
        params.append("building_id", id)
    })
    roomIds.forEach(id => {
        params.append("room_id", id)
    })
    url = url + "?" + params
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data["data"]
}

export const getDeviceDeployments = async (organizationIds, buildingIds, roomIds, deviceIds,
                                           startTime, endTime) => {
    let url = `${base}/v1/devicedeployments`
    let params = new URLSearchParams({})

    organizationIds.forEach(id => {
        params.append("organization_id", id)
    })
    buildingIds.forEach(id => {
        params.append("building_id", id)
    })
    roomIds.forEach(id => {
        params.append("room_id", id)
    })
    deviceIds.forEach(id => {
        params.append("device_id", id)
    })
    if (startTime) {
        params.append("start_time", startTime)
    }
    if (endTime) {
        params.append("end_time", endTime)
    }
    url = url + "?" + params
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data["data"]
}

export const getActiveModelTemplates = async () => {
    let url = `${base}/v1/ml/templates`
    let params = new URLSearchParams({
        active_at: Math.floor(new Date().getTime() / 1000),
        include_devices: 0
    })
    url = url + "?" + params
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data.data
}
export const getAnomalyDetectionResults = async (
    deviceId,
    startTime,
    endTime,
    model_template_ids,
    sort = "",
    limit = 10000
) => {
    let baseurl = `${base}/v1/anomalydetectionresult`


    let params = new URLSearchParams({
        device: deviceId,
        start: Math.floor(startTime.getTime() / 1000),
        end: Math.floor(endTime.getTime() / 1000),
        limit: limit,
    })

    model_template_ids.forEach(id => params.append('model_template_id', id))

    if (sort !== "") {
        params.append("sort", sort)
    }

    let url = baseurl + "?" + params

    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    let returnData = data.data

    let pagination = data.pagination
    let total = pagination.total
    let offset = pagination.offset + limit
    while(total > offset){
        params.set("offset", offset)

        url = baseurl + "?" + params

        let response = await fetch(url, withOptions({method: "GET"}))
        let moreData = await response.json()
        returnData.push(...moreData.data)
        offset += limit
    }


    return returnData
}

export const getDeviceCondition = async (
    deviceIds,
    organizationIds,
    start_time,
    end_time
) => {
    let url = `${base}/v1/device_condition`

    let params = new URLSearchParams({
        start_time: Math.floor(start_time.getTime() / 1000),
        end_time: Math.floor(end_time.getTime() / 1000),
    })
    deviceIds.forEach(deviceId => params.append("device", deviceId))
    organizationIds.forEach(orgId => params.append("organization", orgId))

    url = url + "?" + params

    let response = await fetch(url, withOptions({
        method: "GET",
        credentials: "include",
    }))

    let data = await response.json()
    return data.data
}

export const getDeviceConditionCached = async () => {
    let url = `${base}/v1/device_condition_cached`

    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data["data"]

}

export const getAlarm = async (alarm_id) => {
    let url = `${base}/v1/alarm/${alarm_id}`
    let response = await fetch(url, withOptions({method: "GET"}))
    let data = await response.json()
    return data["data"]
}

export const getAlarms = async (device_ids, org_ids, start_time, end_time, customer_visible) => {
  let url = `${base}/v1/alarm`;
  const params = new URLSearchParams({})
  if (start_time) {
      params.append("start_time", Math.floor(start_time.getTime() / 1000))
  }
  if (end_time ){
      params.append("end_time", Math.floor(end_time.getTime() / 1000))
  }

  if(customer_visible !== undefined){
      params.append("customer_visible", customer_visible)
  }

  device_ids.forEach(deviceId => params.append("device_id", deviceId))
  org_ids.forEach(orgId => {
      if (orgId !== undefined) {
          params.append("organization_id", orgId)
      }}
  )
  url = url + "?" + params
  const response = await fetch(url, withOptions({method: "GET"}));
  const data = await response.json()
  return data['data'];
}

export const getAlarmsByDeviceId = async (device_id, customer_visible) => {
    let url = `${base}/v1/alarm`;
    const params = new URLSearchParams({device_id: [device_id]})
    if(customer_visible !== undefined){
        params.append("customer_visible", customer_visible)
    }
    url = url + "?" + params
    const response = await fetch(url, withOptions({method: "GET"}));
    const data = await response.json()
    return data['data'];
}

export const postAlarm = async (alarm) => {
    let url = `${base}/v1/alarm`
    let body = JSON.stringify(alarm)
    let options = withOptions({method: "POST", body: body, headers: new Headers({ "content-type": "application/json" })})
    let response = await fetch(url, options)
    let data = await response.json()
    return data["data"]
}

export const putAlarm = async (alarm_id, alarm) => {
    let url = `${base}/v1/alarm/${alarm_id}`
    let body = JSON.stringify(alarm)
    let options = withOptions({ method: "PUT", body: body, headers: new Headers({ "content-type": "application/json" })})
    let response = await fetch(url, options)
    let data = await response.json()
    return data["data"]
}

export const publishAlarm = async (alarm_id) => {
    let url = `${base}/v1/alarm/${alarm_id}/publish`
    let options = withOptions({ method: "POST", headers: new Headers({ "content-type": "application/json" })})

    let response = await fetch(url, options)
    return response
}

export const postAlarmFeedback = async (alarm_id, rating, comment) => {
    let url = `${base}/v1/alarmfeedback`
    let body = JSON.stringify({"alarm_id": alarm_id, "rating": rating, "comment": comment})
    let options = withOptions({method: "POST", body: body, headers: new Headers({ "content-type": "application/json" })})
    let response = await fetch(url, options)
    let data = await response.json()
    return data["data"]
}

