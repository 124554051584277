import { TempState } from "../../types/componentTypes"
import { useNavigate, useParams } from "react-router"
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import {
  Alarm as AlarmType,
  Asset,
  Building,
  DeviceDeployment,
  DeviceModelConfig, ModelTemplate,
  ModelType,
  User,
} from "../../types/dataTypes"
import Titlebar from "../../components/Titlebar"
import Header from "../../components/Header"
import Search from "../../components/Search"
import OrganizationSelect from "../../components/OrganizationSelect"
import Button from "../../components/Button"
import {
  getDeviceModelConfig,
  getModelTemplates,
  getModelTypes,
  getUser, postDeviceModelConfig,
  putAsset,
  putBuilding, putDeviceModelConfig,
} from "../../api/api-ts"
import SectionWrapper from "../../components/SectionWrapper"
import { Link } from "react-router-dom"
import { th } from "date-fns/locale"

type AssetSettingsParams = {
  asset_id: string
}

type AssetForm = {
  name: string
  changed: boolean
}
type ThresholdForm = {
  criticalThreshold: string
  warningThreshold: string
  referenceThreshold: string
  changed: boolean
}

export function AssetSettings({ state, updateState }: { state: TempState, updateState: Function }) {
  const params = useParams<AssetSettingsParams>()
  const asset_id = params.asset_id

  const [asset, setAsset] = useState<Asset>()
  const [deviceDeployment, setDeviceDeployment] = useState<DeviceDeployment>()
  const [deviceModelConfigs, setDeviceModelConfigs] = useState<DeviceModelConfig[]>()
  const [thresholdModelConfig, setThresholdModelConfigConfig] = useState<DeviceModelConfig>()
  const [assetForm, setAssetForm] = useState<AssetForm>({ name: "", changed: false })
  const [modelTypes, setModelTypes] = useState<ModelType[]>([])
  const [activeModelTemplates, setActiveModelTemplates] = useState<ModelTemplate[]>([])
  const [hasThresholdModelTemplate, setHasThresholdModelTemplate] = useState<boolean>(false)
  const [thresholdForm, setThresholdForm] = useState<ThresholdForm>({ criticalThreshold: "", warningThreshold: "", referenceThreshold: "", changed: false })
  const [showConfirm, setShowConfirm] = useState<Boolean>()
  const navigate = useNavigate()

  useEffect(() => {
    if (state.assets && state.assets.map(a => a.id).includes(asset_id!))
      setAsset(state.assets.find(a => a.id == asset_id))
  }, [asset_id, state])

  useEffect(() => {

    const fetchDeviceModelConfigs = async (deviceDeployment: DeviceDeployment) => {
      let deviceModelConfigs = await getDeviceModelConfig([deviceDeployment.device_id])
      let modelTypes = await getModelTypes()
      const now = new Date().getTime() / 1000
      deviceModelConfigs = deviceModelConfigs.filter(dmc => dmc.start_time <= now && (dmc.end_time == undefined || dmc.end_time > now))
      setDeviceModelConfigs(deviceModelConfigs)
      setModelTypes(modelTypes)
    }

    const fetchActiveModelTemplatesForDevice = async (deviceDeployment: DeviceDeployment) => {
      let activeModelTemplates = await getModelTemplates([deviceDeployment.device_id], [], [], undefined, undefined, new Date(), undefined, false)
      setActiveModelTemplates(activeModelTemplates)
    }

    if (asset != undefined) {
      //Assume each device deployment has only one asset. This assumption holds per 12.12.2024
      let deviceDeployment = state.deviceDeployments?.find(dd => (dd.assets.map(a => a.id).includes(asset.id)) && (dd.end_time == null || dd.end_time > new Date().getTime() / 1000))
      setDeviceDeployment(deviceDeployment)
      if (deviceDeployment != undefined) {
        fetchDeviceModelConfigs(deviceDeployment)
        fetchActiveModelTemplatesForDevice(deviceDeployment)
      }
      setAssetForm({ name: asset.nice_name ?? asset.name, changed: false })
    }
  }, [asset])

  useEffect(() => {
    if (modelTypes.length > 0) {
      let modelTypeDict: { [id: string]: ModelType } = {}
      modelTypes.forEach(mt => modelTypeDict[mt.id] = mt)

      if (deviceModelConfigs) {
        let thresholdDeviceConfig = deviceModelConfigs?.find((mc: DeviceModelConfig): boolean => {
          return modelTypeDict[mc.model_type_id].name === "threshold"
        })
        setThresholdModelConfigConfig(thresholdDeviceConfig)
      }

      if (activeModelTemplates.length > 0) {
        let thresholdModels = activeModelTemplates.filter(mt => modelTypeDict[mt.model_type_id]["name"] === "threshold")
        if (thresholdModels.length > 0) {
          setHasThresholdModelTemplate(true)
        }
      }
    }
  }, [deviceModelConfigs, modelTypes, activeModelTemplates])

  const onAssetFormChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAssetForm({ ...assetForm, [event.target.name]: event.target.value, changed: true })
  }
  const onThresholdFormChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setThresholdForm({ ...thresholdForm, [event.target.name]: event.target.value, changed: true })
  }

  async function onSubmitAssetSettings(e: FormEvent) {

    function updateAssetState(updatedAsset: Asset) {
      setAsset(updatedAsset)
      let assetIndex = state.assets?.findIndex(a => a.id == updatedAsset.id)
      if (assetIndex && state.assets) {
        state.assets.splice(assetIndex, 1, updatedAsset)
        updateState(state)
      }
    }

    e.preventDefault()
    let nameInputElement = document.getElementsByName("name")[0] as HTMLInputElement
    nameInputElement.value = ""
    if (asset && asset.id) {
      if (assetForm.changed) {
        let updatedAsset: Asset = { ...asset, nice_name: assetForm.name }
        updatedAsset = await putAsset(updatedAsset)

        updateAssetState(updatedAsset)

        let nameInputElemet = document.getElementsByName("name")[0] as HTMLInputElement
        nameInputElemet.value = ""

        setAssetForm({ ...assetForm, changed: false })
      }
      if (thresholdForm.changed && hasThresholdModelTemplate) {
        let thresholdModelType = modelTypes.find(mt => mt.name === "threshold")
        if (thresholdModelConfig == undefined && thresholdModelType && deviceDeployment) {
          let deviceModelConfig: DeviceModelConfig = {
            device_ids: [deviceDeployment.device_id],
            start_time: new Date().getTime() / 1000,
            model_type_id: thresholdModelType.id,
            description: "Threshold model config created from frontend by user",
            training_parameters: {
              "reference_level": thresholdForm.referenceThreshold,
            },
            meta: {},
          }
          deviceModelConfig = await postDeviceModelConfig(deviceModelConfig)
          setThresholdModelConfigConfig(deviceModelConfig)
          setThresholdForm({ ...thresholdForm, changed: false })
        } else if (thresholdModelConfig != undefined) {
          let deviceModelConfig: DeviceModelConfig = {
            ...thresholdModelConfig,
            training_parameters: {
              ...thresholdModelConfig.training_parameters,
              "reference_level": thresholdForm.referenceThreshold,
            },
          }
          deviceModelConfig = await putDeviceModelConfig(deviceModelConfig)
          setThresholdModelConfigConfig(deviceModelConfig)
          setThresholdForm({ ...thresholdForm, changed: false })
        }
      }
    }
    setShowConfirm(false)
  }

  function getTrainingParameterByReference(ref: string): number {
    return (thresholdModelConfig && thresholdModelConfig.training_parameters) ? (Number(thresholdModelConfig.training_parameters[ref] ?? 0.00)) : 0.00
  }

  return (
    <div className="tw-h-full">
      {asset &&
        <div>
          <div>
            <Titlebar headline={"Asset Settings"} />
            <span className={`tw-pt-1 tw-flex-wrap`}>
              <Header headline={asset.nice_name ? asset.nice_name : asset.name} headlineStyles="tw-pt-2">
                <div onClick={() => navigate(-1)}
                     className="tw-absolute tw-right-10">
                  <img
                    src="icon-arrow-circled.svg"
                    alt="exit icon"
                    className="tw-cursor-pointer"
                  />
                </div>
              </Header>
            </span>
          </div>
          <div className={""}>
            <SectionWrapper styles="tw-m-10 tw-p-6 tw-border tw-bg-gray-200">
              <div className={"tw-grid tw-grid-rows-2"}>
                <p className={""}>Asset settings can be configured here. These settings will apply to all users with
                  access to this asset.</p>
                <Link className={""} to={"/account"}>To modify your personal settings, please click here.</Link>
              </div>
            </SectionWrapper>
            <form onSubmit={onSubmitAssetSettings}>
              <SectionWrapper styles="tw-m-10 tw-p-6 tw-border">
                <div className={"tw-text-lg tw-font-bold tw-mb-6"}>General Settings</div>
                <div className={"tw-grid tw-grid-cols-6 tw-gap-4"}>
                  <label className={"tw-font-bold tw-text-right tw-mb-0"} htmlFor="name-setting">Asset Name: </label>
                  <input className={"tw-text-center tw-border tw-border-gray-200 tw-rounded"} id="name-setting"
                         type="text"
                         name={"name"}
                         defaultValue={asset.nice_name ?? ""}
                         placeholder={asset.nice_name ? asset.nice_name : asset.name} onChange={onAssetFormChange} />
                </div>
              </SectionWrapper>
              {(thresholdModelConfig != undefined || hasThresholdModelTemplate) &&
                <SectionWrapper styles="tw-m-10 tw-p-6 tw-border">
                  <div className={"tw-text-lg tw-font-bold tw-mb-2"}>Treshold Settings</div>
                  <p className="tw-mb-4">For assets that use an threshold-based model, set the thresholds for when an
                    alarm should be sent based on vibration or soundlevel</p>
                  <div className={"tw-grid tw-grid-cols-6 tw-gap-4 tw-content-center"}>
                    <label className={"tw-font-bold tw-text-right tw-text-gray-200"}
                           htmlFor="critical-threshold-setting">Critical Threshold</label>
                    <input className={"tw-text-center tw-border tw-border-gray-200 tw-rounded"}
                           id="critical-threshold-setting"
                           type={"number"} step={0.01}
                           placeholder={"0.95"}
                           name={"criticalThreshold"}
                           disabled={true}
                           defaultValue={getTrainingParameterByReference("critical_threshold")}
                           onChange={onThresholdFormChange} />
                  </div>
                  <div className={"tw-grid tw-grid-cols-6 tw-gap-4 tw-content-center"}>
                    <label className={"tw-font-bold tw-text-right tw-text-gray-200"}
                           htmlFor="warning-threshold-setting">Warning Threshold</label>
                    <input className={"tw-text-center tw-border tw-border-gray-200 tw-rounded"}
                           id="warning-threshold-setting"
                           type={"number"} step={0.01}
                           placeholder={"0.75"}
                           name={"warningThreshold"}
                           disabled={true}
                           defaultValue={getTrainingParameterByReference("warning_threshold")}
                           onChange={onThresholdFormChange} />
                  </div>
                  <div className={"tw-grid tw-grid-cols-6 tw-gap-4 tw-content-center"}>
                    <label className={"tw-font-bold tw-text-right"} htmlFor="reference-threshold-setting">Reference
                      Threshold</label>
                    <input className={"tw-text-center tw-border tw-border-gray-200 tw-rounded"}
                           id="reference-threshold-setting"
                           type={"number"} step={0.01}
                           name={"referenceThreshold"}
                           defaultValue={getTrainingParameterByReference("reference_level")}
                           onChange={onThresholdFormChange} />
                  </div>
                </SectionWrapper>}
              {showConfirm ?
                <div className={"tw-flex tw-flex-row tw-pl-12 tw-gap-4"}>
                  <Button id={"submit-button"}
                          styles={"tw-bg-green-400 tw-border-green-400 hover:tw-bg-primary-off-white"} type={"submit"}
                          size={"medium"}>Confirm</Button>
                  <Button id={"cancel-button"}
                          styles={"tw-bg-red-400 tw-border-red-400 tw-cursor-pointer hover:tw-bg-primary-off-white"}
                          type={"button"}
                          size={"medium"} onClick={(e: MouseEvent) => {
                    e.preventDefault()
                    setShowConfirm(false)
                  }}>Cancel</Button>
                  <span className={"tw-flex tw-flex-row tw-bg-white tw-rounded-full tw-px-6"}>
                    <img
                      src="icon-information.svg"
                      alt="information icon"
                      className="tw-stroke-red-400 tw-items-center tw-w-[20px] tw-mr-2"
                    ></img>
                    <p className={"tw-text-bold tw-content-center tw-text-red-800"}> Notice: This action will modify the settings for this building for all users within your organization.</p>
                    </span>
                </div>
                :
                <div className={"tw-relative"}>
                  <Button id={"save-button"} styles={"tw-absolute tw-left-12 tw-cursor-pointer"} type={"button"}
                          size={"medium"} onClick={(e: MouseEvent) => {
                    e.preventDefault()
                    setShowConfirm(true)
                  }}>Save</Button>
                </div>
              }
            </form>
          </div>
        </div>
      }
    </div>
  )
}