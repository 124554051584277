import { Building } from "../../../../types/dataTypes"
import IconStatusPill from "../../../../components/IconStatusPill"
import { BuildingOffice2Icon } from "@heroicons/react/20/solid"
import React from "react"
import { ConditionRow } from "../../../../types/componentTypes"
import DropdownWrapper from "../../../../components/DropdownWrapper"
import GenericSelect from "../../../../components/GenericSelect"
import TrafficLight from "../../../../components/TrafficLight"
import GenericElementSelect from "../GenericElementSelect"

export type BuildingSelectProps = {
  buildings: Building[],
  filteredCmRows: ConditionRow[],
  selectedBuilding?: Building,
  selectBuilding: (building: Building) => void,

}

export default function BuildingSelect({
                                         buildings,
                                         filteredCmRows,
                                         selectedBuilding,
                                         selectBuilding,
                                       }: BuildingSelectProps) {

  let filteredBuildings = buildings
    .filter(b => filteredCmRows.map(r => r.building.id).includes(b.id))
    .sort((a: Building, b: Building) => {
      return (a.name ?? a.address).localeCompare((b.name ?? b.address))
    })

  //Screen less than tailwind.config.js md breakpoint
  if (window.innerWidth > 768 && filteredBuildings.length <= 10) {

    return (
      <div className="tw-flex tw-flex-wrap tw-flex-row tw-gap-4">
        {
          filteredBuildings.map((building) => {

            return (
              <IconStatusPill key={building.id} className={""} selected={selectedBuilding?.id === building.id}
                              setSelected={(id: String) => {
                                selectBuilding(building)
                              }}

                              name={building?.name ?? building.address}
                              id={building.id} status={building.status ?? "UNKNOWN"}
                              icon={<div className={"tw-flex"}><BuildingOffice2Icon
                                className="tw-h-5 tw-w-5 tw-text-gray-400"
                                aria-hidden="true" />
                                {(building.meta && Object.keys(building.meta).includes("muted_to")
                                    && new Date(building.meta["muted_to"] * 1000) > new Date()) &&
                                  <img alt={"muted-building"} src={"muted-alarm-bell.svg"}
                                       className={"tw-h-5 tw-w-5"} />}
                              </div>} />
            )
          })}
      </div>
    )
  } else {
    return (
      <div className="tw-w-full md:tw-w-fit">
        <GenericElementSelect setSelected={selectBuilding}
                              selected={selectedBuilding}
                              className={"tw-w-fit"}
                              available={filteredBuildings}
                              mapAvailableToSelectable={
                                (building: Building) => {
                                  let name = building?.name ?? building.address
                                  return (
                                    {
                                      id: building.id,
                                      element:
                                        <div className="tw-flex tw-gap-2 md:tw-pr-4" key={building.id}>
                                          <TrafficLight status={building.status ?? "UNKNOWN"}></TrafficLight>
                                          <div className="">{name}</div>
                                        </div>,

                                    }
                                  )
                                }
                              } />
      </div>
    )
  }
}