import React, { useState, useEffect, ChangeEvent, useContext } from "react"
import { getAccount, getOrganization } from "../../api/api"
import SectionWrapper from "../../components/SectionWrapper"
import Titlebar from "../../components/Titlebar"
import { capitalizeFirstLetter } from "../../functions/text"
import { AlarmUserPreference, Building, Organization, UserPreferencesForCategory } from "../../types/dataTypes"
import { getOrganizations, getUserPreferences, postApiRotate, updateUserPreferences } from "../../api/api-ts"
import { TempState } from "../../types/componentTypes"
import Button from "../../components/Button"
import { ToastContext } from "../../contexts/ToastContexWrapper"

type AccountType = {
  admin: boolean
  api_key: string
  email: string
  id: string
  name: string
}

type AccountProps = {
  state: TempState
}

export default function Account({ state }: AccountProps) {
  // Fetch account using React hooks
  const [account, setAccount] = useState<AccountType>({
    admin: false,
    api_key: "",
    email: "",
    id: "",
    name: "",
  })

  const [userPreferences, setUserPreferences] = useState<UserPreferencesForCategory[]>()
  const [userAlarmPreferences, setUserAlarmPreferences] = useState<AlarmUserPreference>()
  const [alarmPreferencesChanged, setAlarmPreferencesChanged] = useState<boolean>(false)
  const [organizations, setOrganizations] = useState<Organization[]>()
  const [inConfirm, setInConfirm] = useState<boolean>()

  const toastContext = useContext(ToastContext)

  useEffect(() => {
    const asyncAccount = async () => {
      setAccount(await getAccount())
    }

    asyncAccount().catch(alert)
  }, [])

  useEffect(() => {
    const getAccountSettings = async () => {
      setUserPreferences(await getUserPreferences())
    }
    getAccountSettings()
  }, [account])

  useEffect(() => {
    if (userPreferences !== undefined && userPreferences.length > 0) {
      let alarmPreferences: UserPreferencesForCategory | undefined = userPreferences?.find(p => p.category === "alarm") ?? undefined
      alarmPreferences !== undefined && setUserAlarmPreferences(alarmPreferences.preferences)
    }
  }, [userPreferences])

  useEffect(() => {
    const getOrgs = async () => {
      let orgs: Organization[] = await getOrganizations()
      setOrganizations(orgs)
    }
    if (account.admin) {
      getOrgs()
    }
  }, [account])

  function getUserBuildings(): Building[] {
    if (account.admin) {
      if (organizations !== undefined) {
        let adminOrgs = organizations.filter((o) => o.users?.map(u => u.id).includes(account.id))
        let adminBuildings = state.buildings?.filter(b => adminOrgs.map(o => o.id).includes(b.organization_id)) ?? []
        return adminBuildings
      } else {
        return []
      }
    }
    return state.buildings ?? []
  }

  function onAlarmPreferencesChange(event: ChangeEvent<HTMLInputElement>) {
    let name = event.target.name
    let newPrefs: AlarmUserPreference = { ...userAlarmPreferences! }
    switch (name) {
      case "EMAIL":
        newPrefs.notification_types.EMAIL = event.target.checked
        break
      case "muted_buildings":
        const building_id = event.target.id
        if (newPrefs.muted_buildings.includes(building_id)) {
          let index = newPrefs.muted_buildings.indexOf(building_id)
          newPrefs.muted_buildings.splice(index, 1)
        } else {
          newPrefs.muted_buildings.push(building_id)
        }
        break
      default:
        break
    }
    setAlarmPreferencesChanged(true)
    setUserAlarmPreferences(newPrefs)
  }

  async function saveUserAlarmPreferences() {
    if (userPreferences !== undefined && userAlarmPreferences !== undefined) {
      let oldAlarmPreferences: UserPreferencesForCategory = userPreferences?.find(p => p.category === "alarm")!
      let newAlarmPreferences: UserPreferencesForCategory = {
        category: oldAlarmPreferences.category, id: oldAlarmPreferences.id, preferences: userAlarmPreferences,
      }
      let updatedAlarmPreference = await updateUserPreferences(newAlarmPreferences)
      let newUserPrefList = [...userPreferences]
      let index = newUserPrefList.indexOf(oldAlarmPreferences)
      newUserPrefList.splice(index, 1)
      newUserPrefList.push(updatedAlarmPreference)
      setUserPreferences(newUserPrefList)
      setAlarmPreferencesChanged(false)
    }
  }

  return (
    <div>
      <Titlebar headline="Account" />
      <SectionWrapper styles="tw-m-10 tw-p-6 tw-border">
        <div className={"tw-text-lg tw-font-bold tw-mb-6"}>Account Information</div>
        {account &&
          <div className="md:tw-grid tw-grid-cols-6 md:tw-space-x-4 tw-text-xs md:tw-text-14">
            <div className="tw-font-bold md:tw-text-right">
              Name:
            </div>
            <div className="tw-col-start-2 tw-col-span-5 tw-break-words">
              {account.name}
            </div>
            <div className="tw-font-bold md:tw-text-right">
              Email:
            </div>
            <div className="tw-col-span-5 tw-break-words">
              {account.email}
            </div>
            <div className="tw-font-bold md:tw-text-right">
              Id:
            </div>
            <div className="tw-col-span-5 tw-break-words">
              {account.id}
            </div>
            <div className="tw-font-bold md:tw-text-right">
              Admin:
            </div>
            <div className="tw-col-span-5 tw-break-words">
              {account.admin.toString()}
            </div>
            <div className="tw-font-bold md:tw-text-right">
              Api Key:
            </div>
            <div className="tw-col-span-3 tw-break-words">
              <div id="api-key" hidden={true}>{account.api_key}</div>
            </div>
            <button className={"tw-border tw-border-gray-200 hover:tw-bg-green-200 tw-rounded-full"} type={"button"}
                    onClick={() => {
                      let apiElement = document.getElementById("api-key")
                      if (apiElement !== null) {
                        apiElement.hidden = !apiElement.hidden
                      }

                    }}>Show/Hide
            </button>
            {!inConfirm ?
              <button className={"tw-border tw-border-gray-200 hover:tw-bg-green-200 tw-rounded-full"} type={"button"}
                      onClick={() => {
                        setInConfirm(true)
                      }}>Rotate
              </button> : <div className="tw-w-full">
                <button className={"tw-w-1/2 tw-border tw-border-gray-200 tw-bg-green-200 hover:tw-bg-green-300 tw-rounded-full"} type={"button"}
                        onClick={async () => {
                          try {
                            await postApiRotate(account.id)
                            toastContext("Api Key Rotated", 5, "success")
                            let new_account = await getAccount()
                            setAccount(new_account)
                            setInConfirm(false)
                          } catch (e) {
                            toastContext("Something went wrong", 5, "danger")
                            setInConfirm(false)
                          }
                        }}>Confirm
                </button>
                <button className={"tw-w-1/2 tw-border tw-border-gray-200 tw-bg-red-200 hover:tw-bg-red-300 tw-rounded-full"} type={"button"}
                        onClick={() => {
                          setInConfirm(false)
                        }}>Cancel
                </button>
              </div>
            }
          </div>
        }
      </SectionWrapper>
      <SectionWrapper styles="tw-m-10 tw-p-6 tw-border">
        <div className={"tw-text-lg tw-font-bold tw-mb-6"}>Alarm Settings</div>
        {userAlarmPreferences !== undefined &&
          <form onSubmit={e => e.preventDefault()}>
            <div className="tw-grid tw-grid-cols-6 tw-mb-2 tw-gap-4">
              <label className={"tw-font-bold md:tw-text-right"}>Enable alarm emails:</label>
              <input className={"tw-w-4 tw-h-4"} type={"checkbox"} name={"EMAIL"}
                     checked={userAlarmPreferences.notification_types.EMAIL}
                     onChange={onAlarmPreferencesChange}
              />
            </div>
            <div className="tw-grid tw-grid-cols-6 tw-gap-4">
              <label className={"tw-font-bold md:tw-text-right"}>Enable alarm emails from buildings:</label>
              <div className="tw-grid tw-grid-cols-subgrid tw-col-span-2">
                {getUserBuildings().map(b => (
                  <div className={"tw-flex tw-flex-row tw-gap-4 tw-mb-1 tw-border-b tw-border-gray-200"} id={b.id}>
                    <input className={"tw-w-4 tw-h-fill"} type="checkbox" id={b.id} name={"muted_buildings"}
                           checked={userAlarmPreferences.muted_buildings === undefined || !userAlarmPreferences.muted_buildings.includes(b.id)}
                           onChange={onAlarmPreferencesChange} />
                    <div className={"tw-h-fill"}>{b.name ?? b.address}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={"tw-grid tw-grid-cols-6 tw-w-full tw-h-11"}>
              {alarmPreferencesChanged &&
                <div className={"tw-col-start-5 tw-text-center tw-mt-3"}>Changes to be saved</div>}
              <Button styles={"tw-col-start-6"} type={"submit"} size={"medium"}
                      variant={"primary"} onClick={saveUserAlarmPreferences}>Save</Button>
            </div>
          </form>
        }
      </SectionWrapper>
    </div>
  )
}
