import {Fragment} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {Organization} from "../../types/dataTypes";

type OrganizationSelectProps = {
    selected?: Organization
    setSelected: Function
    availableOrganizations?: Organization[]
    className?: string
}

export default function OrganizationSelect(
    {
        selected,
        setSelected,
        availableOrganizations,
        className
    }: OrganizationSelectProps) {

    return (
        <div className={`${className}`}>
            <div className="md:tw-flex md:tw-flex-row">
                <div className="tw-pb-4 md:tw-pb-0 tw-flex tw-pr-2 tw-items-center md:tw-justify-center">Organization</div> {/*TODO: Fix me*/}
                <Listbox value={selected} onChange={(org: Organization) => setSelected(org)}>
                    <div className="tw-relative tw-w-72 lg:tw-w-72">
                        <Listbox.Button
                            className="tw-relative tw-w-full tw-cursor-default tw-rounded-full tw-bg-white tw-py-2 tw-pl-3 tw-pr-10 tw-text-left tw-text-gray tw-shadow-md tw-focus:outline-none tw-focus-visible:border-indigo-500 tw-focus-visible:ring-2 tw-focus-visible:ring-white tw-focus-visible:ring-opacity-75 tw-focus-visible:ring-offset-2 tw-focus-visible:ring-offset-orange-300 tw-sm:text-sm">
                            <span className="tw-block tw-truncate">{selected?.name ?? "Loading"}</span>
                            <span
                                className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
              <ChevronUpDownIcon
                  className="tw-h-5 tw-w-5 tw-text-gray-400"
                  aria-hidden="true"
              />
            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className="tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none tw-sm:text-sm">
                                {availableOrganizations?.map((org) => (
                                    <Listbox.Option
                                        key={org.id}
                                        className={({active}) =>
                                            `tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-10 tw-pr-4 ${
                                                active ? 'tw-bg-amber-100 tw-text-amber-900' : 'tw-text-gray-900'
                                            }`
                                        }
                                        value={org}
                                    >
                                        {({selected}) => (
                                            <>
                                            <span
                                                className={`tw-block tw-truncate ${
                                                    selected ? 'tw-font-medium' : 'tw-font-normal'
                                                }`}>
                                                {org.name}
                                            </span>
                                                {selected ? (
                                                    <span
                                                        className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3 tw-text-amber-600">
                                                    <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                                                </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
        </div>
    )

}