import { Building, Room } from "../../../../types/dataTypes"
import IconStatusPill from "../../../../components/IconStatusPill"
import { BuildingOffice2Icon, CubeIcon } from "@heroicons/react/20/solid"
import React from "react"
import { ConditionRow } from "../../../../types/componentTypes"
import DropdownWrapper from "../../../../components/DropdownWrapper"
import GenericSelect from "../../../../components/GenericSelect"
import TrafficLight from "../../../../components/TrafficLight"
import GenericElementSelect from "../GenericElementSelect"

export type RoomSelectProps = {
  rooms: Room[],
  filteredCmRows: ConditionRow[],
  selectedRoom?: Room,
  selectedBuilding: Building,
  selectRoom: (room: Room) => void,

}

export default function RoomSelect({
                                     rooms,
                                     filteredCmRows,
                                     selectedRoom,
                                     selectedBuilding,
                                     selectRoom,
                                   }: RoomSelectProps) {

  let filteredRooms = rooms
    .filter(room => filteredCmRows.map(row => row.room.id).includes(room.id) && room.building_id === selectedBuilding.id)
    .sort((a: Room, b: Room) => {
      return ((a.nice_name !== undefined && a.nice_name !== null) ? a.nice_name : a.name)
        .localeCompare((b.nice_name !== undefined && b.nice_name !== null) ? b.nice_name : b.name)
    })

  //Screen less than tailwind.config.js md breakpoint
  if (window.innerWidth > 768 && filteredRooms.length <= 10) {

    return (
      <div className="tw-flex tw-flex-wrap tw-flex-row tw-gap-4">
        {
          filteredRooms.map((room) => {

            return (
              <IconStatusPill key={room.id} className={"tw-text-sm"} selected={selectedRoom?.id === room.id}
                              setSelected={(id: String) => {
                                selectRoom(room)
                              }}

                              name={room?.nice_name ?? room.name}
                              id={room.id} status={room.status ?? "UNKNOWN"}
                              icon={<CubeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"
                                              aria-hidden="true" />} />
            )
          })}
      </div>
    )
  } else {
    return (
      <div className="tw-w-full md:tw-w-fit">
      <GenericElementSelect setSelected={selectRoom}
                            selected={selectedRoom}
                            className={"tw-w-fit"}
                            available={filteredRooms}
                            mapAvailableToSelectable={
                              (room: Room) => {
                                let name = room?.nice_name ?? room.name
                                return (
                                  {
                                    id: room.id,
                                    element:
                                      <div className="tw-flex tw-gap-2" key={room.id}>
                                        <TrafficLight status={room.status ?? "UNKNOWN"}></TrafficLight>
                                        <div className="">{name}</div>
                                      </div>,

                                  }
                                )
                              }
                            } />
        </div>
    )
  }
}