import React, { ChangeEvent } from "react"
import { AnnotationFormData } from "../../types/dataTypes"
import annotationForm from "./index"


export default function AnnotationForm(
  {
    title,
    annotationFormData,
    setAnnotationFormData,
    onSubmit,
    onCancel,
    disableOptions,
    timeRequired,
  }:
    {
      title: string
      annotationFormData: AnnotationFormData,
      setAnnotationFormData: (annotationFormData: AnnotationFormData) => void,
      onSubmit: Function,
      onCancel: Function
      disableOptions?: ("YES" | "NO" | "MAYBE")[]
      timeRequired?: boolean
    }) {

  if (disableOptions === undefined) {
    disableOptions = []
  }

  const onAnnotationFormChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setAnnotationFormData({ ...annotationFormData, [event.target.name]: event.target.value })
  }

  const onClickFormTags = (tag: string, mode: "add" | "remove") => {
    let newData = { ...annotationFormData }
    if (mode === "add") {
      newData["tags"].push(tag)
    }
    if (mode === "remove") {
      let index = newData["tags"].indexOf(tag, 0)
      if (index > -1) {
        newData["tags"].splice(index, 1)
      }
    }
    setAnnotationFormData(newData)
  }


  const options: string[] = ["turn_on_event", "turn_off_event",
    "too_late",
    "human_too_late_to_send",
    "irregular_schedule",
    "too_sensitive",
    "wrong_schedule",
    "bad_data_quality",
    "bad_description",
    "go_home_bot_you_are_drunk",
    "expected_schedule_exception",
    "already_sent_similar",
    "demand_driven",
    "regime_shift", "returned_to_normal",
    "levels_seen_before",
    "still_training",
    "not_relevant",
    "free_cooling",
    "off_when_hot",
    "new_schedule"].sort((a,b) => a.localeCompare(b))


  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        if (annotationFormData.anomaly === undefined) {
          document.getElementById("anomaly-id")!.className = "tw-text-red-400"
        } else if (timeRequired && (annotationFormData.start_time === undefined || annotationFormData.end_time === undefined)) {
          document.getElementById("time-id")!.className = "tw-text-red-400"
        } else {
          onSubmit()
        }
      }
      }
      className={"tw-flex xl:tw-w-96 tw-justify-items-center tw-text-center tw-content-center tw-grid tw-grid-cols-1 tw-content-center tw-bg-primary-off-white tw-text-primary-main-black tw-border-secondary-darker-off-white tw-p-2 tw-space-y-2 tw-border-2 tw-rounded-xl"}>
      <p>{title}</p>
      {timeRequired &&
        <label id="time-id" className={"tw-flex tw-flex-col"}>
          <span>Start Time: {annotationFormData.start_time?.toLocaleString() ?? " "}</span>
          <span>End Time: {annotationFormData.end_time?.toLocaleString() ?? " "}</span>
          <button type="button" className="tw-border-2 tw-border-gray-200" onClick={(e) => {
            setAnnotationFormData({ ...annotationFormData, end_time: undefined, start_time: undefined })
          }}>
            Clear Time
          </button>
        </label>}
      <label className={"tw-flex tw-flex-col"}>
        <span id="anomaly-id">Anomaly (Required) </span>
        <select name="anomaly" onChange={onAnnotationFormChange}>
          <option>Please select one...</option>
          <option value="YES" disabled={disableOptions.includes("YES")}>Yes</option>
          <option value="NO" disabled={disableOptions.includes("NO")}>No</option>
          <option value="MAYBE" disabled={disableOptions.includes("MAYBE")}>Maybe</option>
        </select>
      </label>
      <label className={"tw-flex tw-flex-col tw-w-48 tw-items-center"}>
        <span id="description-id">Description:</span>
        <textarea className={"tw-rounded-md tw-w-72 tw-h-36"} name={"description"}
                  onChange={onAnnotationFormChange} />

      </label>
      <label className={"tw-flex tw-flex-col"}>
        <p>Tags</p>
        <p className="tw-text-sm">Singular, small letters, underscore not space.</p>
        <p className="tw-text-sm">Example: normal_schedule</p>
        <div className="tw-flex-row tw-space-x-1">
          <input type="text" id="tags-input" />
          <input type="button" value="Add" onClick={(event) => {
            onClickFormTags((document.getElementById("tags-input") as HTMLInputElement).value, "add");
            (document.getElementById("tags-input") as HTMLInputElement).value = ""
          }
          } />
        </div>
        <div className="tw-flex-row tw-space-x-1"> .. or ..</div>
        <div className="tw-flex-row tw-space-x-1 tw-mb-1">
          <select name="add" id="select-add" className="tw-w-full tw-mx-4" onChange={e => {
            if (e.target.value != undefined && e.target.value !== "Quick Select Tags...") {
              onClickFormTags(e.target.value, "add")
            }
          }}>
            <option>Quick Select Tags...</option>
            { options.map(opt => <option value={opt}>{opt}</option> ) }
          </select>
        </div>
        <div className="tw-flex tw-flex-col tw-mt-2 tw-border-2 tw-border-gray-200 tw-items-center">
          {annotationFormData.tags.map((v, i) => (
            <div className="tw-flex tw-flex-row tw-space-x-1 tw-items-center" key={i.toString()}>
              <div className="tw-text-400">{i + 1 + ": " + v}</div>
              <input className="tw-border-2 tw-border-gray-200 tw-rounded" type="button" value="Remove"
                     onClick={(event) => {
                       onClickFormTags(v, "remove")
                     }} />
            </div>
          ))}
        </div>
      </label>

      <div className="tw-flex-col tw-space-x-4">
        <input className="hover:tw-text-gray-500 tw-border-2 tw-rounded tw-p-1 tw-border-gray-400" type={"button"}
               value={"Cancel"} onClick={() => onCancel()} />
        <input
          className="tw-text-green-400 hover:tw-text-green-500 tw-border-2 tw-rounded tw-p-1 tw-border-gray-400"
          type="submit" value="Submit" />
      </div>
    </form>)
}