import { DeviceModelConfig } from "../../../types/dataTypes"
import { Layout } from "plotly.js"


/* This method takes a deviceModelConfig which has a threshold parameter in training parameters,
  and uses the value to draw thresholds in the anomaly graph*/
export function addCustomDataFromThresholdModel(thresholdModelConfig: DeviceModelConfig | undefined) {

  return (layout: Partial<Layout>, yLowerRange: number, yUpperRange: number) => {
    // Only add custom plot data if threshold model has thresholds set.
    if (thresholdModelConfig != undefined && thresholdModelConfig.training_parameters
      && Object.keys(thresholdModelConfig.training_parameters).includes("reference_level")) {
      const yLowerRangeWithMargins = yLowerRange - (yUpperRange - yLowerRange) * 0.25
      const yUpperRangeWithMargins = yUpperRange + (yUpperRange - yLowerRange) * 0.25

      let reference_threshold = Number(thresholdModelConfig.training_parameters["reference_level"])

      if (!layout.shapes) {
        layout.shapes = []
      }

      layout.shapes.push({
        xref: "paper",
        x0: 0,
        x1: 1,
        y0: reference_threshold,
        y1: reference_threshold,
        type: "line",
        line: {
          color: "red",
          width: 4,
        },
        label: {
          text: `Reference Threshold = ${reference_threshold}`,
          font: { size: 8, color: "red" },
        },

      })

    }
  }
}