import { Fragment, ReactElement } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"


export type Selectable = {
  element: ReactElement
  id: string | undefined | null
}

interface TypeWithId {
  id: string
}

type GenericElementSelectProps<T extends object> = {
  selected?: T
  setSelected: (selected: T) => void
  mapAvailableToSelectable: (t: T) => Selectable
  available?: T[]
  className?: string
  disabled?: boolean
  border?: string
  selectedName?: string
}

export default function GenericElementSelect<T extends TypeWithId>(
  {
    selected,
    setSelected,
    mapAvailableToSelectable,
    available,
    className = "",
    disabled = false,
    border = "no",
  }: GenericElementSelectProps<T>) {

  const selectable: Selectable[] | undefined = available?.map(mapAvailableToSelectable)
  const selectedSelectable: Selectable | undefined = selectable?.find(s => selected?.id === s.id)

  return (
    <div className={className}>
      <Listbox value={selectedSelectable} onChange={(obj: Selectable) => {
        let found = available?.find(a => a.id === obj.id)
        if (found) {
          setSelected(found)
        }
      }} disabled={disabled}>
        <Listbox.Button
          aria-label="generic-element-select"
          className={`tw-relative tw-w-full tw-cursor-default tw-rounded-full tw-bg-white tw-py-2
                            tw-pl-3 tw-pr-10 tw-text-left tw-text-gray tw-shadow-md tw-focus:outline-none ${border === "yes" ? "tw-border tw-border-black" : "tw-border-none"}tw-focus-visible:border-indigo-500
                            tw-focus-visible:ring-2 tw-focus-visible:ring-white tw-focus-visible:ring-opacity-75 tw-focus-visible:ring-offset-2
                            tw-focus-visible:ring-offset-orange-300 tw-sm:text-sm disabled:tw-text-gray-300`}>
          <span className="tw-block tw-truncate">{selectedSelectable?.element ?? "None"}</span>
          <span
            className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
              <ChevronUpDownIcon
                className="tw-h-5 tw-w-5 tw-text-gray-400"
                aria-hidden="true"
              />
            </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-4/5 md:tw-w-fit tw-overflow-x-hidden tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none tw-sm:text-sm">
            {selectable?.map((obj) => (
              <Listbox.Option
                key={obj.id}
                className={({ active }) =>
                  `tw-relative tw-cursor-pointer tw-select-none tw-py-2 tw-pl-10 tw-pr-4 ${
                    active ? "tw-bg-amber-100 tw-text-amber-900" : "tw-text-gray-900"
                  }`
                }
                value={obj}
              >
                {({ selected }) => (
                  <div id={`selectable-${obj.id}-${selected}`}>
                                            <span
                                              className={`tw-block tw-truncate ${
                                                selected ? "tw-font-medium" : "tw-font-normal"
                                              }`}>
                                                {obj.element}
                                            </span>
                    {selected ? (
                      <span
                        className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3 tw-text-amber-600">
                                                    <CheckIcon title="checkmark" className="tw-h-5 tw-w-5" aria-hidden="true" />
                                                </span>
                    ) : null}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  )

}